:root {
    --color-brand: #186C97;
    --color-brand-dark: #0e3d55;
    --color-brand-gray: #6D6E70;
    --color-gray-light: #EBF1F5;
    --color-white: #FFF;
    --color-blue-additional: #8bd6f7;
}
.body_main:after {
    content: '';
    position: fixed;
    width: 100%;
    height: 10vh;
    bottom: 0;
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
}

.body_main:before {
    content: '';
    position: fixed;
    width: 100%;
    height: 10vh;
    top: 0;
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
}

/*keyframes*/

@keyframes eyes_move {
    50% {
        transform: translate(2px);
    }
    to {

        transform: translate(-2px);
    }
}

@keyframes eyes_close-blink {
    50% {
        visibility: visible;
    }
    to {
        visibility: hidden;
    }
}

@keyframes eyes_close {
    from {
        opacity: 0;
    }
    90% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes web_animation {
    15%, 85% {
        transform: matrix(0.24, 0, 0, 0.24, 6.734, 6.75);
    }
    45% {
        transform: matrix(0.24, 0, 0, 0.24, 6.7799, -291.5042)
    }
    55% {
        transform: matrix(0.24, 0, 0, 0.24, 6.7799, -291.5042)
    }
}

@keyframes mobile_animation {
    15%, 85% {
        transform: matrix(0.24, 0, 0, 0.24, 162.7798, 66.4525);
    }
    45% {
        transform: matrix(0.24, 0, 0, 0.24, 162.7799, -181.5042)
    }
    55% {
        transform: matrix(0.24, 0, 0, 0.24, 162.7799, -181.5042)
    }
}

@keyframes scroll {
    10% {
        opacity: 1;
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes visibility {

    from, to, 10% {
        opacity: 0;
    }
    11%, 99% {
        opacity: 1;
    }
}

@keyframes animate3d {
    from, to, 13%, 29% {
        opacity: 0;
    }

    14%, 27% {
        opacity: 1;
    }
}

/*screen - welcome*/
.screen:first-of-type {
    justify-content: flex-end;
}

@media (max-height: 350px) {
    .screen {
        padding: 80px 0 10vh;
    }
}

svg.screen_image-head {
    width: auto;
    height: 35vh;
    margin-right: 4vw;
    position: relative;
}

.screen_image-head:last-child {
    margin-right: 0;
}

.eyes_move {
    animation: eyes 5s infinite linear;
}

.eyes_close-blink {
    animation: eyes_close-blink .3s linear;
}

.eyes_close {
    animation: eyes_close .3s linear backwards;
}

.eyes-closed {
    visibility: hidden;
}

.screen_image-head-name {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 15px;
    fill: var(--color-brand-gray);
    text-align: center;
    font-weight: bold;
}

.screen_image-head-info {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: bold;
    text-align: center;
    font-size: 9px;
    fill: var(--color-brand-gray);
}

.screen_image-head:hover .screen_image-head-name {
    fill: var(--color-brand);
}

/*screen - web design*/

.web-image_clip {
    animation: web_animation 10s infinite linear;
}

.mobile-image_clip {
    animation: mobile_animation 10s infinite linear;
}

/*screen - retouch*/

.screen_image-retouch path:not(.screen_image-retouch-border) {
    fill: #1C6E98;
    stroke: none;
}

.screen_image-retouch-animate {
    opacity: 0;
    will-change: visibility;
}

.visibility {
    animation: visibility 15s linear infinite;
}

/*screen 3D*/

.screen_image-3d path {
    opacity: 0;
    stroke-width: 2.5;
    transform-origin: center;
    will-change: opacity;
}

/*screen video*/

.screen_image-video--clip {
    clip-path: url(#screen_image-video-01);
    transition: all 1s;
}

.screen_image-video--sourcebox {
    fill: var(--color-brand-dark);
    stroke: none;
    transform: translate(50%, 0px);
}

.screen_image-video--logo {
    fill: var(--color-brand);
    stroke: none;
    transform: translate(-50%, 0px);
}

.screen_image-video--lowerthird {
    will-change: transform;
    transform: translate(0px, 50%);
}

.lowerthird_1 {
    fill: var(--color-blue-additional);
}

.lowerthird_2 {
    fill: var(--color-gray-light);
}

.lowerthird_3 {
    fill: var(--color-brand);
    stroke: none;
}

.willtransform {
    transition: all 1s;
    opacity: 0;
    will-change: transform;
}

.screen_image-video--animation {
    transform: translate(0px, 0px);
    opacity: 1;
    transition: all 1s;
}